import { handleRequest } from "../api/api-request.service";
import { FoodTypeM } from "./food-type.model";
import { MealConsumableI } from "./interfaces/meal-consumable.interface";
import { MediaI } from "./interfaces/media.interface";
import { NutritionalCategoryI } from "./interfaces/nutritional-category.interface";
import { StudentMealAcceptanceI } from "./interfaces/student-meal-acceptance.interface";
import { MediaM } from "./media.model";

export class MealConsumableM implements MealConsumableI {
    id?: number;
    name: string;
    content: string;
    amountInGrams?: number;
    fullMealId?: number;
    media?: MediaI;
    nutritionalCategories: NutritionalCategoryI[];
    studentsAcceptances?: StudentMealAcceptanceI[];
    foodType: FoodTypeM;
    foodTypeId?: number;
    mealType: string;

    constructor({
        id,
        name,
        content,
        amountInGrams,
        fullMealId,
        media,
        nutritionalCategories,
        studentsAcceptances,
        foodType,
        mealType
    } : MealConsumableI) {
        this.id = id;
        this.name = name;
        this.content = content;
        this.amountInGrams = amountInGrams;
        this.fullMealId = fullMealId;
        this.media = media;
        this.nutritionalCategories = nutritionalCategories;
        this.studentsAcceptances = studentsAcceptances;
        this.foodType = foodType;
        this.mealType = mealType;
    }

    static initial(): MealConsumableM {
        return new MealConsumableM({
            name: "",
            content: "",
            media: MediaM.initial(),
            nutritionalCategories: [],
            studentsAcceptances: [],
            foodType : FoodTypeM.initial(),
            mealType : ""
        });
    }

    public static getAll(foodType?: FoodTypeM, search?: string){
        let query = '?limit=1000&offset=0';
    
        if (foodType) {
            query += `&foodType=${foodType.name}`;
        }
        
        if (search) {
            query += `&name=${search}`;
        }
    
        return handleRequest(
            `/meal-consumable/by/institute/1${query}`,
            'GET'
        );
    }

    static async delete(mealConsumableId : number){
        return handleRequest(
            `meal-consumable/${mealConsumableId}`,
            'DELETE',
        );
    }

    static async create(mealConsumable : MealConsumableM){
        return handleRequest(
            `meal-consumable`,
            'POST',
            mealConsumable
        );
    }

    static async update(mealConsumable : MealConsumableM){
        return handleRequest(
            `meal-consumable/${mealConsumable.id}`,
            'PUT',
            mealConsumable
        );
    }
}