import moment from "moment";
import { handleRequest } from "../api/api-request.service";
import { FullMealI } from "./interfaces/full-meal.interface";
import { TodaysMenuI } from "./interfaces/todays-menu.interface";
import { MenuGradeI } from "./interfaces/menu-grade.interface";
import { MenuGradeM } from "./menu-grade.modal";
import { StudentM } from "./student.model";
import { addHours, format } from "date-fns";

export class TodaysMenuM implements TodaysMenuI {
    id?: number;
    weekday: string;
    date: Date;
    userId: number;
    instituteId: number;
    fullmeals: FullMealI[];
    menuGrade?: MenuGradeI;

    constructor({ id, weekday, date, userId, instituteId, fullmeals, menuGrade }: TodaysMenuI) {
        this.id = id;
        this.weekday = weekday;
        this.date = date;
        this.userId = userId;
        this.instituteId = instituteId;
        this.fullmeals = fullmeals;
        this.menuGrade = menuGrade;
    }

    static initial(){
        return new TodaysMenuM({
            weekday : '',
            date : new Date(),
            userId : 1,
            instituteId: 1,
            fullmeals : [],
            menuGrade : MenuGradeM.initial()
        });
    }

    static async getByStudent(student: StudentM){
        const today = format(addHours(new Date(), -3), "yyyy-MM-dd");
        return handleRequest(
            `todays-menu/by/student/${student.id}/today?date=${today}`,
            'GET',
        );
    }

    static async createList(todaysMenus : TodaysMenuM[]){
        return handleRequest(
            `weekly-menu`,
            'POST',
            { weekly : todaysMenus }
        );
    }

    static async updateList(todaysMenus : TodaysMenuM[]){
        return handleRequest(
            `weekly-menu`,
            'PUT',
            todaysMenus
        );
    }

    public static getByDateRange(startDate : Date, endDate : Date){
        const query = `?from=${moment(startDate).format('yyyy-MM-DD')}&to=${moment(endDate).format('yyyy-MM-DD')}&offset=0&limit=1000`;
        return handleRequest(
            `todays-menu${query}`,
            'GET'
        );
    }

}