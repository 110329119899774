import styled from "styled-components";

export const TabOrdersReportContainer = styled.section`
    width: 100%;

    .total{
        padding: 16px 0;
        .export{
            color: var(--primary-color);
            cursor: pointer;
        }
        span{
            font-size: 16px;
            font-weight: bold;
        }
    }
`;

export const TabOrdersReportTitle = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .filters{
        display: flex;
        gap: 8px;
    }

    .datepicker{
        label{
            display: none;
        }
    }

    h2{
        margin-right: auto;

    }
  
`;