import styled from "styled-components";

export const TabOrdersCompletedContainer = styled.section`
    width: 100%;
`;

export const TabOrdersCompletedTitle = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h2{
        margin-right: auto;
    }
  
`;