import { Dispatch } from "redux";
import { OrderController } from "../../../../controllers/order.controller";
import { canteenSetOrdersData } from "../../../../features/canteen-slice.feature";
import { generalReload, generalSetIsLoading } from "../../../../features/general-slice.feature";
import { OrderI } from "../../../../models/interfaces/order.interface";
import { responseStatus } from "../../../../api/api-request.service";
import { DatePickerMultiT } from "../../../components/types/datepicker.type";
import { baseUrl } from "../../../../api/api";

export class TabOrdersReportService {
    dispatch: Dispatch;
    orderController: OrderController;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.orderController = new OrderController;
    }

    public handleSelectedDate(selectedDate: Date | Date[], setDatesSearch: Function) {
        if (Array.isArray(selectedDate)) {
            selectedDate = selectedDate[0];
        }
        const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        setDatesSearch({ startDate, endDate });
    }

    public handleSearch(datesSearch: DatePickerMultiT | undefined, searchStatus: string, page: number) {
        if (datesSearch) {
            this.dispatch(generalSetIsLoading(true));
            this.orderController.getByCanteenId(1, datesSearch!.startDate, searchStatus, page, datesSearch?.endDate).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    this.dispatch(canteenSetOrdersData(data));
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }

    }

    public handleChangeStatus(status: string, orderData: OrderI) {
        this.dispatch(generalSetIsLoading(true));
        this.orderController.update({ ...orderData, orderStatus: status }).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(canteenSetOrdersData(data));
                this.dispatch(generalReload());
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public export(datesSearch: DatePickerMultiT | undefined, searchStatus: string) {
        if (datesSearch) {
            this.dispatch(generalSetIsLoading(true));
    
            this.orderController.export(1, datesSearch.startDate, datesSearch.endDate, searchStatus).then(response => {
                const { status, data } = response;
    
                if (status === responseStatus.SUCCESS && data.url) {
                    const link = document.createElement('a');
                    link.href = `${baseUrl}${data.url}`;
                    link.download = '';  // Nome do arquivo opcional, pode deixar em branco para usar o nome original
                    link.target = '_blank';  // Opcional: abre em uma nova aba
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);  // Remove o link temporário após o clique
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }
    
}

