import React, { useState } from 'react';
import { AddCategoryModalActions, AddCategoryModalContainer, AddCategoryModalContent, AddCategoryModalDescription, AddCategoryModalTitle } from './styled';
import { useDispatch } from 'react-redux';
import { ButtonPrimary } from '../../../components/button-primary.component';
import { useAppSelector } from '../../../../hooks';
import { Input } from '../../../components/input.component';
import { ProductCategoryM } from '../../../../models/product-category.model';
import { AddCategoryModalService } from './service';


export const AddCategoryModal = ({setShowAddCategoryModal, handleGetCategories} : {setShowAddCategoryModal : Function, handleGetCategories : Function}) => {
    const generalReducer = useAppSelector(state=>state.generalReducer);
    const [category, setCategory] = useState<ProductCategoryM>(ProductCategoryM.initial());
    const dispatch = useDispatch();
    const addCategoryModalService = new AddCategoryModalService(dispatch, category, setCategory, setShowAddCategoryModal, handleGetCategories);
    return (
        <AddCategoryModalContainer>
            <AddCategoryModalContent>
                <AddCategoryModalTitle>
                    <span className='primaryBlackSemibold18-150'>Adicionar nova Categoria</span>
                </AddCategoryModalTitle>
                <AddCategoryModalDescription>
                    <Input
                        id='name'
                        name='name'
                        type='text'
                        label='Nome da categoria'
                        placeholder='Nome da categoria'
                        value={category.name}
                        onChange={addCategoryModalService.handleChangeName}
                    />
                </AddCategoryModalDescription>
                <AddCategoryModalActions>
                    <ButtonPrimary onClick={addCategoryModalService.handleSubmit}>
                        Salvar Categoria
                    </ButtonPrimary>
                    <span className='primaryBlackNormal20-150' onClick={()=>setShowAddCategoryModal(false)}>Cancelar</span>
                </AddCategoryModalActions>
            </AddCategoryModalContent>
        </AddCategoryModalContainer>
    );
};
