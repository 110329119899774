import React, { useEffect, useState } from 'react';
import { TabOrdersReportContainer, TabOrdersReportTitle } from './styled';
import { DatePicker } from '../../../components/date-picker.component';
import { Table } from '../../../components/table.component';
import { Dropdown } from '../../../components/dropdown.component';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks';
import { formattedPrice, ordersStatus } from '../../../../utils/config';
import moment from 'moment';
import { TabOrdersReportService } from './service';
import { DatePickerMultiT } from '../../../components/types/datepicker.type';
import { format } from 'date-fns';
import { PaginationComponent } from '../../../components/Pagination';


export const TabOrdersReportPage = () => {
    const [datesSearch, setDatesSearch] = useState<DatePickerMultiT>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const canteenReducer = useAppSelector((state) => state.canteenReducer);
    const generalReducer = useAppSelector((state) => state.generalReducer);
    const [searchStatus, setSearchStatus] = useState('Finalizado');
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();

    const tabOrdersReportService = new TabOrdersReportService(dispatch);

    useEffect(() => {
        tabOrdersReportService.handleSearch(datesSearch, searchStatus, page);
    }, [page]);

    useEffect(()=>{
        if(page === 1){
            tabOrdersReportService.handleSearch(datesSearch, searchStatus, page);
        }else{
            setPage(1);
        }
    },[datesSearch, searchStatus, generalReducer.reload])

    useEffect(() => {
        if (selectedDate) {
            tabOrdersReportService.handleSelectedDate(selectedDate, setDatesSearch);
        }
    }, [selectedDate]);

    return (
        <TabOrdersReportContainer>
            <TabOrdersReportTitle>
                <h2 className='primaryBlackSemibold28-150'>{datesSearch ? `Pedidos de ${format(datesSearch!.startDate, 'dd/MM/yyyy')} à ${format(datesSearch!.endDate, 'dd/MM/yyyy')}` : ''} </h2>
                <div className='filters'>
                    <Dropdown
                        styleClass='input-style'
                        containerClassName='dropDownStyle'
                        optionsClassName={'dropOptionsStyle'}
                        defaultValue={searchStatus}
                        placeholder='Status'
                        options={[{ value: 'all', content: 'Todos' }, { value: 'Finalizado', content: 'Finalizado' }, { value: 'Em Produção', content: 'Em Produção' }, { value: 'Cancelado', content: "Cancelado" }]}
                        onChange={(value) => {
                            setSearchStatus(value)
                        }}
                    />
                    <DatePicker
                        dates={datesSearch}
                        setDate={setSelectedDate}
                        selectsRange={true}
                        className='datepicker'
                    />
                </div>
            </TabOrdersReportTitle>

            {canteenReducer.ordersData?.count === 0 ?
                <div className='empty-table mt'>
                    <span className='primaryBlackSemibold20-150'>Nenhum pedido em produção no dia {moment(selectedDate).format('DD/MM/yyyy')}</span>
                </div> :
                <>
                    <div className='total'>
                        <span>Total de {canteenReducer.ordersData?.count} pedidos ({parseFloat(canteenReducer.ordersData?.totalValue?.toString() || '0').toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}) - </span>
                        <span className='export' onClick={()=>tabOrdersReportService.export(datesSearch, searchStatus)}> Exportar Resultado</span>
                    </div>
                    <Table>
                        <thead className='primaryBlackSemibold20-150'>
                            <th>Pedido</th>
                            <th>Aluno</th>
                            <th>Decrição de Valor</th>
                            <th>Status</th>
                        </thead>
                        <tbody>
                            {canteenReducer.ordersData?.rows?.map((order) => {
                                let amountPrice = 0;
                                return (
                                    <tr key={order.id}>
                                        <td className='info-product'>
                                            {order.orderItems?.map((orderItem, index) => {
                                                amountPrice += orderItem.unitPrice * orderItem.quantity;
                                                return (
                                                    <React.Fragment key={index}>
                                                        <a href='#' className='primaryBlackNormal18-150'>
                                                            {orderItem.product?.name}
                                                        </a>
                                                        {index !== (order.orderItems!.length - 1) && <br />}
                                                    </React.Fragment>
                                                )
                                            })}
                                            <span className='primaryGreyNormal16-150 overflow'>
                                                Você pode clicar no nome do produto para visualizá-lo
                                            </span>
                                        </td>

                                        <td>
                                            <span className='item-column primaryGreyNormal18-150'>
                                                Nome do aluno{' '}
                                                <span className='primaryBlackNormal18-150'>{order.student?.name}</span>
                                            </span>
                                            <span className='item-column primaryGreyNormal18-150'>
                                                Ano/Year<span className='primaryBlackNormal18-150'>{order.student?.grade?.name}</span>
                                            </span>
                                        </td>

                                        <td>
                                            <span className='item-column primaryBlackNormal18-150'>
                                                {order.orderItems?.map((orderItem, index) => (
                                                    <span>{orderItem.quantity}x {formattedPrice(orderItem.unitPrice)}</span>
                                                ))}
                                            </span>
                                            <span className='item-column primaryPrimarySemibold18-150'>
                                                Valor Total <span className='primaryBlackSemibold20-150'>{formattedPrice(amountPrice)}</span>{' '}
                                            </span>
                                        </td>
                                        <td>
                                            <div className='column-status'>
                                                <Dropdown
                                                    options={[{ value: 'Finalizado', content: 'Finalizar' }]}
                                                    className='dropdown-status'
                                                    placeholder={order.orderStatus}
                                                    onChange={(value: string) => tabOrdersReportService.handleChangeStatus(value, order)}
                                                />
                                                {/* <OptionsMenu className='info-more' /> */}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </>


            }
            <PaginationComponent page={page} setPage={setPage} totalPage={Math.ceil((canteenReducer.ordersData?.count || 1) / 10)} />
        </TabOrdersReportContainer>
    );
};