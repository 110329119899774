import styled from "styled-components";

export const PaginationContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    font-size: 16px;

    .disabled{
        background-color: #ddd;
    }
`;

export const PaginationArrow = styled.div`
    background-color: #ff6242;
    color: #fff;
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    cursor:pointer;
`;

export const PaginationPage = styled.div`

`;
