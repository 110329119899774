import { MenuAddItemPage } from "../../views/modules/canteen/MenuAddItem";
import { TabActiveOrdersPage } from "../../views/modules/canteen/TabActiveOrders";
import { TabOrdersCompletedPage } from "../../views/modules/canteen/TabOrdersCompleted";
import { TabMenuPage } from "../../views/modules/canteen/TabMenu";
import { OrderI } from "../../models/interfaces/order.interface";
import { TabOrdersReportPage } from "../../views/modules/canteen/TabOrdersReport";

const tabsNames = {
    ACTIVE_ORDERS : 'Pedidos Ativos', 
    ORDERS_COMPLETED : 'Pedidos Finalizados',
    MENU : "Cardápio",
    ORDERS_REPORT : "Relatório de Pedidos",
}

export const canteenContentsNames = {
    ACTIVE_ORDERS : 'active-orders', 
    ORDERS_COMPLETED : 'orders-completed',
    MENU : "menu",
    MENU_ADD_ITEM: 'menu-add-item',
    ORDERS_REPORT : "orders-reports",
}

export const canteenTabToContent = {
    [tabsNames.ACTIVE_ORDERS] : canteenContentsNames.ACTIVE_ORDERS,
    [tabsNames.ORDERS_COMPLETED] : canteenContentsNames.ORDERS_COMPLETED,
    [tabsNames.MENU] : canteenContentsNames.MENU,
    [tabsNames.ORDERS_REPORT] : canteenContentsNames.ORDERS_REPORT
}

export const canteenTabList = Object.values(tabsNames);

export const canteenContents = {
    [canteenContentsNames.ACTIVE_ORDERS] : <TabActiveOrdersPage/>,
    [canteenContentsNames.ORDERS_COMPLETED] : <TabOrdersCompletedPage/>,
    [canteenContentsNames.MENU] : <TabMenuPage/>,
    [canteenContentsNames.MENU_ADD_ITEM] : <MenuAddItemPage/>,
    [canteenContentsNames.ORDERS_REPORT] : <TabOrdersReportPage/>
}

export type CanteenOrdersDataStateT = {
    count : number,
    rows : OrderI[],
    totalValue?: number
}

export type CanteenStateT = {
    tabSelectedItem : string,
    tabSelectedContent : string,
    ordersData?: CanteenOrdersDataStateT
}