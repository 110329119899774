import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { CanteenPage } from "../modules/canteen/Home";
import { Sidebar } from "../components/sidebar.component";
import { useAppSelector } from "../../hooks";
import { Loading } from "../components/loading.components";
import { Toast } from "../components/toast.component";
import { AlertDialog } from "../components/alert-dialog.component";
import { FoodPage } from "../modules/food/home.page";
import { SettingsPage } from "../modules/settings/home.page";
import { AnnouncementsPage } from "../modules/announcements/home.page";
import { StudentHomePage } from "../modules/students/students-home.page";
import { DashboardHomePage } from "../modules/dashboard/dashboard-home.page";
import { LocalToken } from "../../utils/local-token";
import { LoginUserType } from "../../utils/config";
import { useDispatch } from "react-redux";
import { RoutesService } from "./Routes.service";
import { useEffect } from "react";
import { isMobile } from 'react-device-detect';
import logo_image from '../../assets/images/logo146x146.png';
import { MobileContainer } from "./Routes.styled";
import { ResetPasswordPage } from "../modules/auth/ResetPassword";
import { ForgoutPasswordPage } from "../modules/auth/ForgoutPassword";
import { LoginPage } from "../modules/auth/Login";
import { LadingPage } from "../modules/auth/Landing";

const RoutesApp = ()=>{
    const routesWithoutDash = ['landing', 'login', 'esqueci-minha-senha', 'password-reset'];
    const currentPath = window.location.pathname.split("/")[1];
    const generalReducer = useAppSelector(state => state.generalReducer);
    const token = LocalToken.getStoredToken();
    const userType = LocalToken.getStoredUserType();
    const dispatch = useDispatch();
    const routesService = new RoutesService(dispatch, generalReducer.userSchoolWorker);
    
    
    useEffect(()=>{
        
        routesService.loadAuthenticatedUser()
    }, []);

    // if (isMobile && userType !== 'STUDENT' &&  generalReducer.userSchoolWorker?.groups?.find(group=>group.name === "SCHOOL_ADMINISTRATION") ) {
    if(false){
        return (
            <MobileContainer>
                <img src={logo_image} alt="Logo do Healthy Puppy"/>
                <p className="primaryPrimarySemibold20-150"> Para uma experiência mais completa, acesse o Healthy Puppy em um computador.</p>
                <p className="primaryBlackNormal20-150">Assim, você poderá aproveitar ao máximo todos os recursos disponíveis. Agradecemos pela compreensão!</p>
            </MobileContainer>
        );
    }

    return token && userType === LoginUserType.TEACHER ?  (
        <BrowserRouter basename="/">
            <div className="panel">
                { !routesWithoutDash.includes(currentPath) && <Sidebar/> }
                <div className="content">
                    <Routes>
                        <Route path="/" element={<DashboardHomePage/>}  />
                        <Route path="/home" element={<DashboardHomePage/>}  />
                        <Route path="/cantina" element={<CanteenPage/>}  />
                        <Route path="/alimentacao" element={<FoodPage/>}  />
                        <Route path="/alunos" element={<StudentHomePage/>}  />
                        <Route path="/comunicados" element={<AnnouncementsPage/>} />
                        <Route path="/configuracoes" element={<SettingsPage/>} />
                    </Routes>
                </div>
    
                { generalReducer.alertDialog.isOpen && <AlertDialog/>}
                { generalReducer.isLoadig && <Loading/>}
                {<Toast/>}
                
            </div>
        </BrowserRouter>
    ) : 
    userType === LoginUserType.STUDENT ? (
        <BrowserRouter basename="/">
            <div className="panel">
                { !routesWithoutDash.includes(currentPath) && <Sidebar/> }
                <div className="content">
                    <Routes>
                        <Route path="/*" element={<Navigate to="/alimentacao"/>} />
                        <Route path="/alimentacao" element={<FoodPage/>}  />
                    </Routes>
                </div>

                { generalReducer.alertDialog.isOpen && <AlertDialog/>}
                { generalReducer.isLoadig && <Loading/>}
                {<Toast/>}
                
            </div>
        </BrowserRouter>
    ) :
    (
        <BrowserRouter basename="/">
            <div className="panel">
                <div className="content">
                    <Routes>
                        <Route path="/*" element={<Navigate to="/landing"/>} />
                        <Route path="/landing" element={<LadingPage/>} />
                        <Route path="/login/:userType" element={<LoginPage/>} />
                        <Route path="/esqueci-minha-senha" element={<ForgoutPasswordPage/>} />
                        <Route path="/password-reset" element={<ResetPasswordPage/>} />
                    </Routes>
                </div>
    
                { generalReducer.alertDialog.isOpen && <AlertDialog/>}
                { generalReducer.isLoadig && <Loading/>}
                {<Toast/>}
                
            </div>
        </BrowserRouter>
    ) 

}

export default RoutesApp;