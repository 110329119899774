import React from 'react';
import { useDispatch } from 'react-redux';
import { HomeContainer } from './styled';
import { HomeService } from './service';
import { useAppSelector } from '../../../../hooks';
import { TabBar } from '../../../components/tab-bar.component';
import { canteenContents, canteenTabList } from '../../../../features/types/canteen-slice.type';

export const CanteenPage = () => {
    
    const canteenReducer = useAppSelector((state) => state.canteenReducer);
    const dispatch = useDispatch();

    const homeService = new HomeService(dispatch);

    return (
        <HomeContainer>
           <h2 className='primaryBlackSemibold28-120'>Cantina</h2> 
           <span className='primaryBlackNormal20-120'>Acompanhe os pedidos e informações da Cantina</span>

           <TabBar 
                setSelectedTab={(tabSelectedItem)=>homeService.handleSetSelectedTab(tabSelectedItem)} 
                selected={canteenReducer.tabSelectedItem} 
                items={canteenTabList}
            />
        
           { canteenContents[canteenReducer.tabSelectedContent] }
           
        </HomeContainer>
    );
};