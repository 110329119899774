import { PaginationArrow, PaginationContainer, PaginationPage } from "./styled"

export const PaginationComponent = ({ page, setPage, totalPage }: { page: number; setPage: Function; totalPage: number }) => {
    return (
        <PaginationContainer>
            <PaginationArrow
                className={page === 1 ? 'disabled' : ''}
                onClick={() => {
                    if (page > 1) {
                        setPage(page - 1)
                    }
                }}
            >
                {'<'}
            </PaginationArrow>

            <PaginationPage>Página {page} de {totalPage}</PaginationPage>

            <PaginationArrow
                className={page === totalPage ? 'disabled' : ''}
                onClick={() => {
                    if (page < totalPage) {
                        setPage(page + 1)
                    }
                }}
            >
                {'>'}
            </PaginationArrow>
        </PaginationContainer>
    )
}