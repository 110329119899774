import React, { useEffect, useState } from 'react';
import { TabOrdersCompletedContainer, TabOrdersCompletedTitle } from './styled';
import { DatePicker } from '../../../components/date-picker.component';
import { Table } from '../../../components/table.component';
import { Dropdown } from '../../../components/dropdown.component';
import { OptionsMenu } from '../../../components/options-menu.component';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks';
import { formattedPrice } from '../../../../utils/config';
import moment from 'moment';
import { TabOrdersCompletedService } from './service';

export const TabOrdersCompletedPage = () => {
    const [dateSearch, setDateSearch] = useState(new Date());
    const canteenReducer = useAppSelector((state) => state.canteenReducer);
    const generalReducer = useAppSelector((state) => state.generalReducer);
    
    const dispatch = useDispatch();

    const tabOrdersCompletedService = new TabOrdersCompletedService(dispatch);

    useEffect(()=>{
        tabOrdersCompletedService.handleSearch(dateSearch);
    }, [dateSearch, generalReducer.reload]);

    return (
        <TabOrdersCompletedContainer>
           <TabOrdersCompletedTitle>
                <h2 className='primaryBlackSemibold28-150'>Pedidos Finalizados</h2> 
                <DatePicker 
                    date={dateSearch} 
                    setDate={setDateSearch}
                    buttonText='Ver todos os pedidos'
                />
            </TabOrdersCompletedTitle>
            
            {canteenReducer.ordersData?.count === 0 ?
                <div className='empty-table mt'>
                    <span className='primaryBlackSemibold20-150'>Nenhum pedido finalizado {dateSearch ? `no dia ${moment(dateSearch).format('DD/MM/yyyy')}` : 'em todo o periodo'}</span> 
                </div> :

                <Table>
                    <thead className='primaryBlackSemibold20-150'>
                        <th>Pedido</th>
                        <th>Aluno</th>
                        <th>Decrição de Valor</th>
                        <th>Status</th>
                    </thead>
                    <tbody>
                        {canteenReducer.ordersData?.rows?.map((order) => {
                            let amountPrice = 0;
                            return (
                                <tr key={order.id}>
                                    <td className='info-product'>
                                        {order.orderItems?.map((orderItem, index) => {
                                            amountPrice += orderItem.unitPrice * orderItem.quantity;
                                            return (
                                                <React.Fragment key={index}>
                                                    <a href='#' className='primaryBlackNormal18-150'>
                                                        {orderItem.product?.name}
                                                    </a>
                                                    {index !== (order.orderItems!.length - 1) && <br />}
                                                </React.Fragment>
                                            )
                                        })}
                                        <span className='primaryGreyNormal16-150 overflow'>
                                            Você pode clicar no nome do produto para visualizá-lo
                                        </span>
                                    </td>

                                    <td>
                                        <span className='item-column primaryGreyNormal18-150'>
                                            Nome do aluno{' '}
                                            <span className='primaryBlackNormal18-150'>{order.student?.name}</span>
                                        </span>
                                        <span className='item-column primaryGreyNormal18-150'>
                                            Ano/Year<span className='primaryBlackNormal18-150'>{order.student?.grade?.name}</span>
                                        </span>
                                    </td>

                                    <td>
                                        <span className='item-column primaryBlackNormal18-150'>
                                            {order.orderItems?.map((orderItem, index) => (
                                                <span>{orderItem.quantity}x {formattedPrice(orderItem.unitPrice)}</span>
                                            ))}
                                        </span>
                                        <span className='item-column primaryPrimarySemibold18-150'>
                                            Valor Total <span className='primaryBlackSemibold20-150'>{formattedPrice(amountPrice)}</span>{' '}
                                        </span>
                                    </td>
                                    <td>
                                        <div className='column-status'>
                                            <Dropdown
                                                color={'#CAF5CF'} 
                                                className='dropdown-status'
                                                placeholder={order.orderStatus}
                                            />
                                            {/* <OptionsMenu className='info-more' /> */}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        
                    </tbody>
                    <div className='full-line'>
                        <span className='primaryPrimarySemibold18-150'>Ver mais</span>
                    </div>
                </Table>
            }
        </TabOrdersCompletedContainer>
    );
};