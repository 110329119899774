import moment from "moment";
import { handleRequest } from "../api/api-request.service";
import { OrderI } from "./interfaces/order.interface";
import { ProductOrderI } from "./interfaces/product-order.interface";
import { StudentI } from "./interfaces/student.interface";

export class OrderM implements OrderI {
    id?: number;
    orderStatus: string;
    orderCode: string;
    orderTime: Date;
    orderItems?: ProductOrderI[];
    student?: StudentI;
    studentId: number;
    canteenId: number;

    constructor({
        id,
        orderStatus,
        orderCode,
        orderTime,
        orderItems,
        student,
        studentId,
        canteenId,
    }: OrderI) {
        this.id = id;
        this.orderStatus = orderStatus;
        this.orderCode = orderCode;
        this.orderTime = orderTime;
        this.orderItems = orderItems;
        this.student = student;
        this.studentId = studentId;
        this.canteenId = canteenId;
    }

    static async getByCanteenId(
        canteenId : number, date : Date | null, limit : number, offset : number, status: string, toDate?: Date | null
    ){
        let query = `?limit=${limit}&offset=${offset}`;
        if(status !== "all"){
            query+=`&status=${status}`;
        }
        if(date){
            let to = date;
            if(toDate){
                to = toDate;
            }
            query+= `&from=${moment(date).format('yyyy-MM-DD')}&to=${moment(to).format('yyyy-MM-DD')}`;
        }
        return handleRequest(
            `order/by/canteen/${canteenId}${query}`,
            'GET'
    )}

    static async update(
        orderData : OrderI
    ){
        return handleRequest(
            `order/${orderData.id}`,
            'PUT', 
            orderData
    )}

    static async export(
        canteenId : number, date : Date | null, toDate: Date | null, status?: string
    ){
        let query = `?`;
        if(status !== "all"){
            query+=`&status=${status}`;
        }
        if(date){
            let to = date;
            if(toDate){
                to = toDate;
            }
            query+= `&from=${moment(date).format('yyyy-MM-DD')}&to=${moment(to).format('yyyy-MM-DD')}`;
        }
        return handleRequest(
            `exports/export-sheets/by/canteen/1${query}`,
            'GET',
    )}
}
